<template>
  <BaseLayout wrapperClass="home" v-if="isClient">
    <HeroBanner />
    <ImageGallery />
    <SubscribeSeparator />
    <MostPurchased v-if="hrEnabled" />
    <Retargeted v-if="hrEnabled" />
    <OurStores />
    <OurBrands />
  </BaseLayout>
</template>

<script setup>
import { ref, computed, onMounted, watchEffect } from "vue";
import { useStore } from "vuex";
import config from "@config";
import { useHead } from "@unhead/vue";
import VueCookies from "vue-cookies";
import BaseLayout from "@/esf_thehague_fietskledingvoordeel/core/components/BaseLayout.vue";
import HeroBanner from "@/base/core/components/HeroBanner.vue";
import ImageGallery from "@/base/core/components/ImageGallery.vue";
import SubscribeSeparator from "@/base/core/components/SubscribeSeparator.vue";
import MostPurchased from "@/base/core/components/home/MostPurchased.vue";
import Retargeted from "@/base/core/components/home/Retargeted.vue";
import OurStores from "@/base/core/components/OurStores.vue";
import OurBrands from "@/base/core/components/OurBrands.vue";

const store = useStore();

const isClient = ref(false);
const hrEnabled = ref(config.helloRetail && config.helloRetail.enabled);

const discountHeaderDesktopImages = computed(() =>
  store.getters["sliders/getSliderByIdentifier"]("discount_header")
);
const heroBannerImages = computed(() => store.getters["sliders/getSliderByIdentifier"]("home"));
const homeGalleryImages = computed(() =>
  store.getters["sliders/getSliderByIdentifier"]("home_gallery")
);
const discountHeaderMobileImages = computed(() =>
  store.getters["sliders/getSliderByIdentifier"]("discount_header_mobile")
);

const cmsDefaultPageContent = computed(() => store.state.cmsPage.default);

// Meta information
useHead({
  title: () => "Home",
  meta: [
    {
      name: "title",
      content: () => (cmsDefaultPageContent.value ? cmsDefaultPageContent.value.meta_title : ""),
    },
    {
      name: "keywords",
      content: () => (cmsDefaultPageContent.value ? cmsDefaultPageContent.value.meta_keywords : ""),
    },
    {
      name: "description",
      content: () =>
        cmsDefaultPageContent.value ? cmsDefaultPageContent.value.meta_description : "",
    },
  ],
});

watchEffect(() => {
  if (import.meta.env.SSR && discountHeaderDesktopImages.value?.slides.length) {
    const discountHeaderDesktopPreloadLinks = discountHeaderDesktopImages.value.slides.map(
      (image) => ({
        rel: "preload",
        as: "image",
        href: image?.media?.url,
        type: image?.media?.url.endsWith(".png") ? "image/png" : "image/jpeg",
      })
    );

    useHead({
      link: discountHeaderDesktopPreloadLinks,
    });
  }
});

watchEffect(() => {
  if (import.meta.env.SSR && heroBannerImages.value?.slides.length) {
    const heroBannerPreloadLinks = heroBannerImages.value.slides.map((image) => ({
      rel: "preload",
      as: "image",
      href: image?.media?.url,
      type: image?.media?.url.endsWith(".png") ? "image/png" : "image/jpeg",
    }));

    useHead({
      link: heroBannerPreloadLinks,
    });
  }
});

watchEffect(() => {
  if (import.meta.env.SSR && discountHeaderMobileImages.value?.slides.length) {
    const discountHeaderMobilePreloadLinks = discountHeaderMobileImages.value.slides.map(
      (image) => ({
        rel: "preload",
        as: "image",
        href: image?.media?.url,
        type: image?.media?.url.endsWith(".png") ? "image/png" : "image/jpeg",
      })
    );

    useHead({
      link: discountHeaderMobilePreloadLinks,
    });
  }
});

watchEffect(() => {
  if (import.meta.env.SSR && homeGalleryImages.value?.slides.length) {
    const homeGalleryPreloadLinks = homeGalleryImages.value.slides.map((image) => ({
      rel: "preload",
      as: "image",
      href: image?.media?.url,
      type: image?.media?.url.endsWith(".png") ? "image/png" : "image/jpeg",
    }));

    useHead({
      link: homeGalleryPreloadLinks,
    });
  }
});

// Handle mounted lifecycle
onMounted(async () => {
  isClient.value = true;
  if (hrEnabled.value) {
    const hello_retail_id = VueCookies.get("hello_retail_id"); // Using the default import

    if (hello_retail_id) {
      await store.dispatch("home/loadRetargetedProducts", {
        hello_retail_id,
        page: "home",
      });
      await store.dispatch("home/loadMostPurchasedProducts", {
        hello_retail_id,
      });
    }
  }
});
</script>
